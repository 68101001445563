#safety {
  background: #fff !important;
  padding-bottom: 100px;
  //HEADER
  .header {
    background-image: url("../images/Safety/FRT_Safety_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;

    h1 {
      color: #ffffff;
    }
  }

  .quest {
    padding: 40px 20px;
    color: #000000;

    h3 {
      color: #000000;
      //margin-left: 21px;
      font-weight: 800;
      font-size: 34px;
      text-align: center;
    }

    hr {
      width: 190px;
      height: 6px;
      background-color: $red;
      opacity: 1;
      margin: 10px auto 40px;
    }

  }

  .mission {
    display: flex;
    flex-direction: row;


    p {
      padding: 0 50px;
    }

    @media screen and (max-width: $md) {
      flex-direction: column;
      p {
        padding: 0;
      }
    }

  }

  .guidingPrinciples {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $md) {
      flex-direction: column !important;

    }
  }

  //Boxes
  .boxes {
    hr {
      width: 10%;
      margin: 0 auto;

      height: 3px;
      background-color: $red;
      opacity: 1;
    }

    .box-row {
      justify-content: center;

      .box {
        margin: 20px 0 0 0;
        padding: 30px 0;
        box-shadow: 2px 4px 8px #0000005F;
        display: flex;
        flex-direction: row;

        .box-title {
          display: flex;

          span {
            display: block;
            width: 20px;
            height: 32px;
            background-color: $red;

          }

          h3 {
            margin-left: 21px;
            font-weight: 800;
            font-size: 34px;
          }
        }

        p {
          color: #000000;
        }

        .box-content {
          padding: 0 50px;

          p {
            color: #000;
          }

          a {
            color: #000;
          }

          h3 {
            font-weight: normal;
          }
        }
      }

      .bullets {
        background-image: url("../images/Safety/red-texture.jpg");
        background-size: cover;
        background-position: center;
        margin: 0;
        padding: 50px 0;

        h2, p {
          color: #fff;
          margin: 10px 0 10px 41px;

          i {
            margin-right: 17px;
          }
        }

      }

      .incentivesWrapper, .awards {
        h3 {
          font-weight: normal;
        }
        .box{
          @media screen and (max-width: $md){
            flex-direction: column !important;
          }
        }
      }

      .incentives {
        background-image: url("../images/Safety/red-texture.jpg");
        background-size: cover;
        background-position: center;
        margin: 0;
        padding: 20px 35px;


        h2, p {
          color: #fff;
        }
      }
    }

    .box-row .excellence {

      .box {
        padding: 0 !important;

        .excellenceWrappper {
          padding: 30px 0 !important;

          .box-content {
            display: block;
            padding: 36px 50px 48px 50px;

            h3 {
              font-weight: 800;
            }
          }
        }

        .excellenceImg {
          background-image: url("../images/Safety/GettyImages-1159755467.jpg");
          background-position: center;
          background-size: cover;
          @media screen and (max-width: $md){
            display: none;
          }
        }
      }


    }
  }
}