#terminals {
  background: #fff !important;
  padding-bottom: 100px;
  //HEADER
  .header {
    background-image: url("../images/Careers/FRT_Terminals_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;


    h1 {
      color: #ffffff;
    }

  }

  h2{
    text-align: center;
    color: #000;
  }

  img{
    width: 100%;
    height: auto;
  }

  .address{
    padding: 20px;
    text-align: left;
    width: 15rem;
    margin: 0 auto;
    h4{
      color: $red;
      font-size: 34px;
      margin: 20px 0;
    }
    p{
      color: #000;
      margin: 0;
      padding: 0;
      font-size: 18px;
    }
  }




}