#careers {
  background: #fff;
  //HEADER
  .header {
    background-image: url("../images/Careers/FRT_Career_Header_2.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px;

    h1 {
      color: #ffffff;
    }
  }

  //Boxes
  .boxes {
    padding: 40px 20px;

    h3 {
      text-align: center;
      color: #000000;
      font-weight: 600;
    }

    hr {
      width: 190px;
      margin: 0 auto;
      height: 6px;
      background-color: $red;
      opacity: 1;
      margin: 0 auto 40px;
    }

    .box-row {
      justify-content: center;

      .box {
        margin: 20px 0;
        padding: 0;
        box-shadow: 2px 4px 8px #0000005F;

        .box-title {
          display: flex;
          align-items: center;
          border-bottom: 5px solid $red;
          padding: 15px 20px;

          img {
            height: 56px;
            margin: 10px;
          }

          h3 {
            font-weight: bold;
            color: #000000;
            font-size: 24px;
            margin: 0;
            text-align: left;
          }
        }

        .box-card-img {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 158px;
        }

        .box-footer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background: #000;
          color: #fff;
          padding: 15px 30px;
          font-size: 25px;
          text-decoration: none;

          p {
            margin-bottom: 0;
          }

          i.fa-arrow-right {
            color: $red !important;
          }
        }

        .box-footer:hover {
          text-decoration: underline;
        }

      }

    }
  }

  //TERMINAL & OFFICE
  .terminalOffice {
    background: #fff;
    //text-align: center;
    padding: 50px;

    h3,p,{
      text-align: center;
    }

    hr{
      color: $red;
      height: 6px;
      width: 190px;
      opacity: 100%;
      text-align: center;
      margin: 10px auto 40px;
    }
    p{
      color: #707070;
      margin-bottom: 40px;
    }

    ul{
      display: table;
      margin: 0 auto 2em;
    }
    a{
      text-decoration:none;
      color: #707070;
    }
  }

  .terminalOffice:nth-child(odd) {
    background-color: #F0F0F0;
  }

}