//GRID BREAKPOINTS
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

//Colors
$red:#B42025;
$red-hover: #ca3b40;
$gray:#F0F0F0;


//SPACING
$top-bottom-padding: 5rem 0;