@font-face {
  font-family: alfa;
  src: url(../images/font/AlfaSlabOne-Regular.ttf);
}

#military {
  h3, h2{
    font-family: alfa !important;
    letter-spacing: .1rem !important;
    font-weight: normal !important;
  }
  .military-header {
    background-image: url("../images/Careers/FRT_Military_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    height: 400px;

    @media screen and (max-width: 500px) {
      background-position: left;
    }

    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 94px;
      //line-height: 0.6;
      font-weight: bold;
      text-align: center;


      @media screen and (max-width: 992px) {
        font-size: 62px;
      }
      @media screen and (max-width: 400px) {
        font-size: 42px;
        line-height: 1;
      }
    }

    h2 {
      color: #ffffff;
      font-size: 57px;
      line-height: 1;
      font-weight: normal;
      font-family: alfa !important;

      @media screen and (max-width: 992px) {
        font-size: 34px;
      }
      @media screen and (max-width: 400px) {
        font-size: 28px;
      }
    }

    a {
      color: #ffffff;
      background-color: $red;
      padding: 10px 30px;
      display: inline-block;
      text-decoration: none;
      font-size: 31px;
      margin-top: 25px;

      @media screen and (max-width: 992px) {
        font-size: 20px;
      }
    }

    a:hover {
      background-color: #cb9b39;
    }
  }




  #military_intro {
    background-color: #FFFFFF;
    padding: 40px 20px;

    h3 {
      text-align: center;
      color: #000;
      font-size: 34px;
      font-weight: bold;
      //border-bottom: solid 2px $red;
    }

    hr {
      width: 190px;
      margin: 0 auto;
      height: 6px;
      background-color: $red;
      opacity: 1;
      margin: 0 auto 40px;
    }

    p {
      color: #000;
      text-align: center;
    }

  }

  #military_about{
background: #fff;
    padding: 40px 20px;

    .box{
      box-shadow: 2px 4px 8px #0000005f;
      .left {
padding: 0 !important;
        .top {
          display: flex;
          //padding-bottom: 30px;
          span {
            display: block;
            width: 21px;
            height: 32px;
            background-color: $red;
            //margin-right: 32px;
          }

          h3 {
            margin-left: 21px;
            font-weight: 800;
            font-size: 34px;
          }
        }
        .bottom{
          margin: 10px 42px;
          color: #000;
        }


      }
    }


    .right{
      background-image: url("../images/Careers/FRT_MilitaryBodyImage.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 500px;
    }
  }

  #military_training {
    background-color: #F0F0F0;
    padding: 40px 20px;

    .trainingContainer {
      text-align: center;

      img {
        padding: 0 0 40px 0;
        width: 100%;
        max-width: 600px;
        height: auto;
      }

      h3 {
        text-align: center;
        color: $red;
        font-size: 34px;
        font-weight: bold;
      }

      hr {
        width: 190px;
        margin: 0 auto;
        height: 6px;
        background-color: $red;
        opacity: 1;
        margin: 0 auto 40px;
      }

      p {
        color: #000;
        text-align: center;
      }
    }


  }

  #military_benefits{
    background-color:$red;
    padding: 40px 20px;

      h3 {
        color: #fff;
        font-size: 26px;
        font-weight: bold;
      }

      li{
        color: #fff;
      }


  }

  .banner {
    background-image: url("../images/Careers/FRT_Military_Camo.jpg");
    background-size: cover;
    padding: 40px;
    display: flex;
    align-items: center;

    h3 {
      font-size: 40px;
      color: #fff;
      //font-size: 3vw;
      margin: 10px;
      font-weight: 800;

      @media screen and (max-width: 992px) {
        font-size: 25px;
      }
      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }

    a {
      color: #ffffff;
      background-color: $red;
      padding: 10px 30px;
      display: inline-block;
      text-decoration: none;
      font-size: 31px;
      font-weight: bold;
      margin: 10px;
      @media screen and (max-width: 992px) {
        font-size: 20px;
      }
      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
    }

    a:hover {
      background-color: $red-hover;
    }
  }
}
