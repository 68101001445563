#independentContractors {
  background: #fff !important;
  //HEADER
  .header {
    background-image: url("../images/Independent_Contractor/FRT_IndependentContractor_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 350px;

    @media screen and (max-width: 1500px){
      background-position: right;
    }
    @media screen and (max-width: $xl){
      background-position: left;
    }
    h3{
      color: #fff;
      font-weight: normal;
      margin: 0 !important;
    }
    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 94px;
      margin: 0 !important;
      font-weight: bold;

      @media screen and (max-width: 992px) {
        font-size: 62px;
      }
      @media screen and (max-width: 400px) {
        font-size: 42px;
        line-height: 1;
      }
    }

    button{
      color: #fff;
      background: $red;
      font-size: 31px;
      padding: 20px 40px;
    }
  }

  .intro {
    padding: 40px 20px;

    h3 {
      color: #000000;
      //margin-left: 21px;
      font-weight: 800;
      font-size: 34px;
      text-align: center;
    }

    p {
      margin-top: 40px;
      color: #000;
      text-align: center;
    }

    hr {
      width: 10%;
      margin: 0 auto;
      height: 3px;
      background-color: $red;
      opacity: 1;
    }
  }

  //Benefits
  .benefits{
    background: #ffffff;
    color: #000;
    padding: 100px;
  }
  .benefits:nth-child(odd){
    background: #F0F0F0 !important;
  }
  //Tractor
  .tractor{
    color: #000 !important;
    padding: 100px;
  }
.benefits, .tractor{
  span {
    height: 15px;
    width: 75px;
    display: block;
    background-color: $red;
  }

  h3 {
    margin: 15px 0;

  }
}

}