#home {
  .home-header {
    background-image: url("../images/Home_Page/FRT_Header_Home.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    height: 400px;

    @media screen and (max-width: 500px) {
      background-position: left;
    }

    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 94px;
      //line-height: 0.6;
      font-weight: bold;

      @media screen and (max-width: 992px) {
        font-size: 62px;
      }
      @media screen and (max-width: 400px) {
        font-size: 42px;
        line-height: 1;
      }
    }

    h2 {
      color: #ffffff;
      font-size: 57px;
      line-height: 1;
      font-weight: normal;

      @media screen and (max-width: 992px) {
        font-size: 34px;
      }
      @media screen and (max-width: 400px) {
        font-size: 28px;
      }
    }

    a {
      color: #ffffff;
      background-color: $red;
      padding: 10px 30px;
      display: inline-block;
      text-decoration: none;
      font-size: 31px;
      margin-top: 25px;

      @media screen and (max-width: 992px) {
        font-size: 20px;
      }
    }

    a:hover {
      background-color: $red-hover;
    }
  }

  .bio {
    background: #fff;
    //padding: 40px 20px;
    padding: 5rem 0;

    p {
      color: #000;
      font-size: 18px;
    }
  }


  #home_careers {
    background-color: #F0F0F0;
    padding: 40px 20px;

    h3 {
      text-align: center;
      text-decoration: underline;
      text-decoration-color: $red;
      text-decoration-thickness: 4px;
      text-underline-offset: 7px;
      padding-bottom: 40px;
      color: #000;
      font-size: 34px;
      font-weight: bold;
    }

    .card-col {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card {
        width: 16rem;
        background-color: #ffffff;
        margin: 10px;
        position: relative;
        height: 380px;

        @media screen and (max-width: 1400px) {
          height: 435px;
        }
        @media screen and (max-width: 1200px) {
          height: 460px;
        }
        @media screen and (max-width: 992px) {
          height: 285px;
        }
        @media screen and (max-width: 500px) {
          height: 315px;
        }

        .img-circle{
          background-color: #F0F0F0;
          border-radius: 50%;
          display: inline-block;
          padding: 12px 16px;
          margin: 15px 0;
          transform: translate(10%, 10%);
        }
        img {
          margin: 10px;
          height: 70px;
          //padding: 20px 30px;
        }


        .cardCopy {
          background: #fff !important;
          position: relative;
          padding: 15px;
          z-index: 1;
          transition: margin-top 0.5s;

          h4 {
            color: #000;
            margin: 10px;
          }

          p {
            color: #000;
            margin: 10px;

          }
        }

      }


      .learnMore {
        z-index: -1;
        position: absolute;
        bottom: 0;
        height: 30%;
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $red;
        background-size: cover;

        a {
          text-decoration: none;
          color: #fff;

          p {
            margin: 0;
            padding: 0;
          }
        }
      }

      .card:hover > .cardCopy {
        margin-top: -124px;
      }

      .card:hover > .learnMore {
        z-index: 0;
      }
    }

    @media screen and (max-width: 992px) {
      .card-col {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card {
          width: 90%;
        }
      }
    }
  }

  .banner {
    background-image: url("../images/Home_Page/SafetyImage.jpg");
    background-size: cover;
    padding: 40px;
    display: flex;
    align-items: center;

    h3 {
      font-size: 40px;
      color: #fff;
      //font-size: 3vw;
      margin: 10px;
      font-weight: 800;

      @media screen and (max-width: 992px) {
        font-size: 25px;
      }
      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }

    a {
      color: #ffffff;
      background-color: $red;
      padding: 10px 30px;
      display: inline-block;
      text-decoration: none;
      font-size: 31px;
      font-weight: bold;
      margin: 10px;
      @media screen and (max-width: 992px) {
        font-size: 20px;
      }
      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
    }
    a:hover {
      background-color: $red-hover;
    }
  }
}
