#footerNav {
  background-color: #000000;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 5px solid $red;

  .brand-logo {
    img {
      height: auto;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .navSocial {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    @media screen and (max-width: 576px) {
      align-items: center;
    }
    #nav {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media screen and (max-width: 576px) {
        flex-direction: column;
      }

      a {
        text-decoration: none;
        color: #ffffff !important;
        margin: 0 15px;
      }

      a:hover {
        color: $red-hover !important;
      }

      a.active {
        color: $red-hover !important;
      }
    }

    .socials {
      margin: 15px;

      a {
        text-decoration: none;

        img, svg {
          width: 40px;
        }
      }

    }
  }
}