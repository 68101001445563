#benefitsContainer {
  background: #fff !important;
  color: #000;

  .header {
    background-image: url("../images/Careers/FRT_Benefits_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;

    h1 {
      color: #ffffff;
    }
  }


  //HISTORY
  .benefits {
    padding: 40px 20px;

    .benefits-row {
      justify-content: center;
      margin-top: 50px;

      span {
        height: 15px;
        width: 75px;
        display: block;
        background-color: $red;
      }

      li {
        margin: 15px 0;
      }

      h3 {
        margin: 15px 0;

      }
    }
  }
  .benefits:nth-child(odd){
    background: #F0F0F0 !important;
  }

    .healthcare, .disability, .lifeInsurance {
    background: #F0F0F0 !important;

  }
}