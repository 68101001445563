#companyDrivers {
  background: #fff !important;
  //HEADER
  .header {
    background-image: url("../images/Company_Driver/FRT_CD_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 350px;

    @media screen and (max-width: 1500px) {
      background-position: right;
    }
    @media screen and (max-width: $xl) {
      background-position: left;
    }

    h3 {
      color: #fff;
      font-weight: normal;
      margin: 0 !important;
      text-transform: uppercase;
    }

    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 94px;
      margin: 0 !important;
      font-weight: bold;

      @media screen and (max-width: 992px) {
        font-size: 62px;
      }
      @media screen and (max-width: 400px) {
        font-size: 42px;
        line-height: 1;
      }
    }

    button {
      color: #fff;
      background: $red;
      font-size: 31px;
      padding: 20px 40px;
    }
  }

  .intro {
    padding: 40px 20px;

    h3 {
      color: #000000;
      //margin-left: 21px;
      font-weight: 800;
      font-size: 34px;
      text-align: center;
    }

    p {
      margin-top: 40px;
      color: #000;
      text-align: center;
    }

    hr {
      width: 10%;
      margin: 0 auto;
      height: 3px;
      background-color: $red;
      opacity: 1;
    }
  }


  //Boxes
  .boxes {


    hr {
      width: 10%;
      margin: 0 auto;

      height: 3px;
      background-color: $red;
      opacity: 1;
    }

    .box-row {
      justify-content: center;

      .box {
        margin: 20px 0 0 0;
        padding: 30px 0;
        box-shadow: 2px 4px 8px #0000005F;
        display: flex;
        flex-direction: row;

        .box-title {
          display: flex;

          span {
            display: block;
            width: 20px;
            height: 32px;
            background-color: $red;

          }

          h3 {
            margin-left: 21px;
            font-weight: 800;
            font-size: 34px;
          }
        }

        p {
          color: #000000;
        }

        .box-content {
          padding: 0 50px;

          p {
            color: #000;
          }

          a {
            color: #000;
          }

          h3 {
            font-weight: normal;
          }
        }
      }

      .offer {
        .box {
          display: flex;
          flex-direction: row;

          @media screen and (max-width: $md) {
            flex-direction: column !important;

          }

        }

        .offerBullets {
          background-image: url("../images/Company_Driver/red-texture.jpg");
          background-size: cover;
          background-position: center;
          margin: 0;
          display: flex;
          padding: 50px 0;

          p {
            font-size: 32px;
          }

          h2, p {
            color: #fff;
            margin: 10px 40px;

            i {
              margin-right: 40px;
            }
          }

        }
      }

    }

  }

  .MatricksBlocks {
    background-color: #ffffff;
    color: #000;
    padding: 100px;

    span {
      height: 15px;
      width: 75px;
      display: block;
      background-color: $red;
    }

    h3 {
      margin: 15px 0;
    }
  }

  .MatricksBlocks:nth-child(even) {
    background: #F0F0F0 !important;
  }
}