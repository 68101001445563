#about {
  background: #fff;
  color: #000;

  .header {
    background-image: url("../images/About_us/FRT_AboutUs_Header.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    height: 350px;

    h1 {
      display: none;
    }
  }

  //BLURB
  .blurb {
    //padding: 40px 20px;
    padding: $top-bottom-padding;


    h2 {
      text-align: center;
      text-decoration: underline;
      text-decoration-color: $red;
      text-decoration-thickness: 4px;
      text-underline-offset: 7px;
      padding-bottom: 40px;
      color: #000;
      font-size: 34px;
      font-weight: bold;
    }

    .blurb-left {
      p {
        font-size: 18px;
      }
    }

    .blurb-right {
      display: flex;
      @media screen and (max-width: 768px) {
        margin-top: 25px;
      }

      a {
        display: block;
      }

      p {
        margin-left: 10px;
        font-size: 20px;

        span {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }

  }

  #widget {
    padding: $top-bottom-padding;
    background: #F0F0F0;

    h2 {
      text-align: center;
      text-decoration: underline;
      text-decoration-color: $red;
      text-decoration-thickness: 4px;
      text-underline-offset: 7px;
      color: #000;
      font-size: 34px;
      font-weight: bold;
    }

    .socialLinks, .blog, .twitter {
      margin: 20px 0;
      padding: 20px;
    }

    .socialLinks{
      text-align: center;
    }

    .blog {
      h3 {
        font-size: 25px;
      }

      a {
        text-decoration: none;
        color: #000;

        .bi {
          color: $red;
        }
      }

    }

    .socials {
      a {
        text-decoration: none;
        margin: 15px;


        img, svg {
          width: 60px;
        }
      }
    }
  }

  //VIDEO
  .video {
    background-color: #F5F5F5;

    padding: $top-bottom-padding;

    .video-left {

      .video-text {
        border: solid 1px #464646;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-right: 63px;

        .video-top {
          display: flex;
          //padding-bottom: 30px;
          span {
            display: block;
            width: 21px;
            height: 32px;
            background-color: $red;
            margin-right: 32px;
          }

          h3 {
            margin-left: 10px;
            font-weight: 800;
            font-size: 34px;
          }
        }

        .video-middle {
          padding-top: 30px;
          margin-left: 63px;
        }

        .video-bottom {
          padding-top: 30px;
          margin-left: 63px;

          a {
            font-size: 25px;
            font-weight: 600;
            color: #000;
            text-decoration: none;

            span {
              color: $red;
            }
          }
        }
      }
    }

    .video-right {

      img{
        //max-width: 500px;
        width: 100%;
        height: auto;
      }
      .embed-responsive {
        position: relative;
        display: block;
        height: 0;
        padding: 0;
        overflow: hidden;
        padding-bottom: 56.25%;

        @media screen and (max-width: 768px) {
          margin-top: 50px;
        }

        iframe {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }


  //HISTORY
  .history {
    padding: 40px 20px;

    .history-row {
      justify-content: center;
      margin-top: 50px;

      span {
        height: 15px;
        width: 75px;
        display: block;
        background-color: $red;
      }

      li {
        margin: 15px 0;
      }

      h3 {
        margin: 15px 0;

      }
    }
  }
}