#services {
  background: #fff;
  //HEADER
  .header {
    background-image: url("../images/Services/FRT_Services_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;

    h1 {
      color: #ffffff;
    }
  }

  //Boxes
  .boxes {
    padding: 40px 20px;
    h3 {
      text-align: center;
      color: #000000;
      font-weight: 600;
    }

    hr {
      width: 190px;
      height: 6px;
      background-color: $red;
      opacity: 1;
      margin: 10px auto 40px;
    }

    .box-row {
      justify-content: center;

      .box {
        margin: 20px 0;
        padding: 30px 0;
        box-shadow: 2px 4px 8px #0000005F;
        //height: 270px;

        .box-title {
          display: flex;
          padding: 20px 0;

          span {
            display: block;
            width: 20px;
            height: 32px;
            background-color: $red;

          }

          h3 {
            margin-left: 21px;
            font-weight: 800;
            font-size: 34px;
          }
        }
        .box-content{
          padding: 0 50px;

          p{
            color: #000;
          }

          a{
            color: #000;
          }
          #download{
            color: $red;
          }
        }

      }
    }
  }
}