#businessCenter {
  background: #fff !important;
  //HEADER
  .header {
    background-image: url("../images/Business_Center/FRT_BusinessCenter_Header.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;

    h1 {
      color: #ffffff;
    }
  }

  .links {
    background: #F0F0F0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 430px;

    button {
      background: #9B782C;
      color: #fff;
      width: 260px;
      height: 68px;
      font-size: 20px;
      margin: 10px;
    }
  }

  .loginForm {
    padding: 50px;
    p {
      color: #000;
      font-size: 18px;
    }
    #fname, #lname{
      height: 50px;
      width: 72%;
      margin-top: 10px;
    }
    .loginBtn {
      background: #9B782C;
      color: #fff;
      height: 50px;
      width: 20%;
    }

  }


}