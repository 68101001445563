//@import "variables";
#contact {
  background: #fff !important;
  //HEADER
  .header {
    background-image: url("../images/Contact_Us/FRT_Contact_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;

    h1 {
      color: #ffffff;
    }
  }

  //Boxes
  .boxes {

    hr {
      width: 10%;
      margin: 0 auto;
      height: 3px;
      background-color: $red;
      opacity: 1;
    }

    .box-row {
      justify-content: center;

      .box {
        margin: 20px 0 0 0;
        padding: 30px 0 70px 0;
        box-shadow: 2px 4px 8px #0000005F;
        //display: flex;
        //flex-direction: cou

        .box-title {
          display: flex;
          span {
            display: block;
            height: 32px;
            width: 21px;
            background-color: $red;
          }

          h3 {
            margin-left: 21px;
            font-weight: 800;
            font-size: 34px;
          }
        }

        .box-content {
          padding: 0 50px;
          color: #000000;

          p{
            margin: 0;
          }
        }

        .email {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;

            h3{
              font-size: 24px;
            }
          img {
            //height: auto;
            //width: 100%;
            height: 88px;
            margin: 10px;
          }
          a{
            color: $red;

          }

          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            flex-grow: 1;
          }
        }
      }
    }
  }

  .customerService {
    background: #F0F0F0;
    padding: 60px;
    margin-top: 50px;

    div {
      text-align: center;

      span {
        a{
          color: $red;

        }
      }
    }

  }
}

