@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  //font-family: "Avenir Next", "Avenir", sans-serif;
  font-family: 'Encode Sans', sans-serif;
}

p {
  font-size: 18px;
}

h1 {
  font-size: 94px;
  text-transform: uppercase;
}

h3 {
  font-weight: 800;
  font-size: 34px;
  color: #000;
}

ul {
  margin: 0;
  line-height: 1.4;
}

li {
  font-size: 18px;
  color: #000;
}

//HEADER STYLES
.header {
  h1 {
    font-size: 3rem;
  }

  button {
    color: #fff;
    background: $red;
    font-size: 31px;
    padding: 20px 40px;
  }

  a {
    color: #fff;
    background: $red;
    font-size: 31px;
    padding: 20px 40px;
    margin-top: 25px;
    display: inline-block;
    text-decoration: none;
  }

  a:hover {
    background: $red-hover;
  }
}



@media screen and (max-width: $sm) {
  .header {
    h1 {
      font-size: 3rem;
    }

    a {
      font-size: 1rem;
    }
  }
}