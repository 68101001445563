body {
  margin: 0;
  padding: 0;
  background: #232323;
  color: #cdcdcd;
  font-family: "Avenir Next", "Avenir", sans-serif;
}

nav {
  background-color: #000000;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .brand-logo {
    img {
      height: auto;
      width: 100%;
      max-width: 180px;
    }
  }

  #desktopNav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    a {
      text-decoration: none;
      color: #ffffff !important;
      margin: 0 15px;
      font-size: 18px;
    }

    a:hover {
      color: $red !important;
    }

    a.active {
      color: $red !important;
    }

    @media screen and (max-width: 776px) {
      display: none !important;
    }
  }


  #desktopSubNav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    a {
      text-decoration: none;
      color: #ffffff !important;
      padding: 0 1rem;
      border-right: 1px solid #707070;
      font-size: 15px;
    }

    a:hover {
      color: $red !important;
    }

    a.active {
      color: $red !important;
    }

  }

  @media screen and (max-width: 768px) {
    #desktopSubNav {
      display: none;
    }
  }
  /*hamburger css*/


  #menuToggle {
    display: none;
    position: relative;
    top: 0px;
    right: 0;
    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  @media screen and (max-width: 768px) {
    #menuToggle {
      display: block;
    }
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;
    display: block;
    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }


  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  #menuToggle #mobileSubNav a {
    display: block !important;
    color: #000;


    li {
      font-size: 18px;
      padding: 5px 0;
      list-style-type: none;
    }
  }

  #menuToggle #mobileSubNav a {
    background: #ededed !important;
  }
  #menuToggle #mobileSubNav a.subActive {
    background: #c5c5c5 !important;
  }

  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }


  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }


  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }


  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }


  #menu {
    position: absolute;
    width: 300px;
    margin: -100px 0 0 -250px;
    padding: 50px;
    padding-top: 125px;

    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  }

  #menu li {
    padding: 10px 0;
    font-size: 22px;
  }

  #menu .active {
    background: #c5c5c5 !important;
  }


  #menuToggle input:checked ~ ul {
    transform: none;
  }

}