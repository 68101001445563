#training {
  background: #fff !important;
  //HEADER
  .header {
    background-image: url("../images/Careers/FRT_Training_Header.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;


    h1 {
      color: #ffffff;
    }
  }

  //Boxes
  .boxes {
    padding: 40px 20px;

    hr {
      width: 10%;
      margin: 0 auto;
      height: 3px;
      background-color: $red;
      opacity: 1;
    }

    .box-row {
      justify-content: center;

      .box {
        margin: 20px 0;
        padding: 0;
        box-shadow: 2px 4px 8px #0000005F;

        .box-title {
          display: flex;
          padding: 20px 0;

          span {
            display: block;
            width: 20px;
            height: 32px;
            background-color: $red;

          }

          h3 {
            margin-left: 21px;
            font-weight: 800;
            font-size: 34px;
          }
        }

        .box-content {
          background-image: url("../images/Training/red-texture.jpg");
          background-size: cover;
          background-position: center;
          margin: 0;
          display: flex;
          padding: 30px 0 50px 0;

          h2, p{
            color: #fff;
            margin: 10px 0 10px 41px;
          }
        }

        .email {
          display: flex;
          justify-content: center;

          img {
            height: 50%;
            margin: 10px;
          }

          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 20px;
          }
        }
      }
    }
  }

  .apply {
    background: #F0F0F0;
    padding: 50px;

    div {
      text-align: center;
    }
    h2, p{
      color: #000;
      font-size: 34px;
    }
    button{
      background: $red;
      color: #fff;
      padding: 20px 60px;
      font-size: 31px;
      border: none;
    }
  }
}